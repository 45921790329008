<template>
  <div>
    <v-card>
      <v-container fluid>
        <v-row no-gutters style="width: 100%;">
          <!-- Menú de navegación a la izquierda -->
          <v-navigation-drawer
            permanent
            expand-on-hover
          >
            <v-list>
              <!--v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
                </v-list-item-avatar>
              </v-list-item-->

              <v-list-item link @click="contenido='Informacion'" style="min-height: 30px;margin-top:0; margin-bottom: 0; padding-top: 5px; padding-bottom: 0;">
                <v-list-item-icon style="height: 30px;margin-top:0; margin-bottom: 0; padding-bottom: 0;">
                  <svg-icon type="mdi" :path="mdiInformationVariantCircleOutline"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title style="height: 30px;margin-top:0; margin-bottom: 0; padding-bottom: 0;"><small>Información</small></v-list-item-title>
              </v-list-item>
            </v-list>

            <v-divider style="margin:0px"></v-divider>
            
            <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h7">
                    Perfiles de Visualización
                  </v-list-item-title>
                  <v-list-item-subtitle>Seleccione un perfil</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            <v-list nav dense>
              <v-list-item link @click="seleccionarPerfil('General')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiAccountGroup"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title>General</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="seleccionarPerfil('Incorporación')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiCardPlus"></svg-icon>
                  <!--v-icon>mdi-folder</v-icon-->
                </v-list-item-icon>
                <v-list-item-title>Incorporación</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="seleccionarPerfil('Capitalización')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiCashRegister"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title>Capitalización</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="seleccionarPerfil('CDAM')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiDatabaseCheck"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title>CDAM</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="seleccionarPerfil('Planeación')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiChartGantt"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title>Planeación</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="seleccionarPerfil('Materiales')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiPackageVariantPlus"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title>Materiales</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="seleccionarPerfil('Atención Regional')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiMapMarkerRadius"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title>Atención Regional</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="seleccionarPerfil('Caracterización')">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiTagMultiple"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title>Caracterización</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-divider style="margin:0px"></v-divider>
            <v-list>
              <v-list-item link @click="contenido='Anexos'">
                <v-list-item-icon>
                  <svg-icon type="mdi" :path="mdiFileDocumentMultipleOutline"></svg-icon>
                </v-list-item-icon>
                <v-list-item-title><small>Anexos</small></v-list-item-title>
              </v-list-item>
            </v-list> 

          </v-navigation-drawer>
          
          <!-- Contenido de la tabla a la derecha -->
          <v-col>
            <div v-if="contenido=='Datos'">
              <!--v-card-title>{{ perfilSeleccionado }}</v-card-title-->
              <v-container style="padding-top: 0; max-width: 100%;">      
                <v-row>
                  <v-col cols="3" md="3" class="d-flex">
                    <h4>{{ perfilSeleccionado }}</h4>
                  </v-col>
                  <v-col cols="9" md="9" class="d-flex" style="z-index: 3;">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header> Filtros de búsqueda</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-container class="divFiltros">
                            <v-form v-model="valid">
                              <v-row>
                                <v-col cols="12" md="12">
                                  <template v-for="x, index in subConsulta.campos_busqueda">
                                    </hr>
                                  </template>
                                  
                                  <table class="tabla">
                                    <thead class="v-data-table-header">
                                      <tr>
                                        <th class="text-left">
                                          Filtro
                                        </th>
                                        <th class="text-left">
                                          Valor
                                        </th>
                                        <th class="text-center">
                                          Eliminar
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in subConsulta.campos_busqueda"
                                        :key="item.name"
                                      >
                                        <td>
                                          <v-select
                                              v-model="item.filtro"
                                              :items="filteredCampos"
                                              :item-text="'text'"
                                              :item-value="'value'"
                                              attach
                                              clearable
                                              label="Criterio de búsqueda"
                                              :rules="required"
                                              @change="onFiltroChange"
                                              style="text-transform: uppercase;"
                                            ></v-select>
                                        </td>
                                        <td>
                                          <v-text-field
                                            v-model="item.valor"
                                            label="Valor"
                                            single-line
                                            counter
                                            clearable
                                            hide-details="auto"
                                            :rules="required"
                                          ></v-text-field>
                                        </td>
                                        <td class="text-center">
                                          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                                        </td>
                                      </tr>
                                    </tbody>
                                  
                                  </table>
                                  
                                  <div class="d-flex justify-content-between align-items-center" style="margin: 3px;">
                                    
                                    <CButton
                                        color="primary"
                                        class="px-4"
                                        :disabled="!(valid)"
                                        @click="consultar()"
                                        ><CIcon name="cil-magnifying-glass" /> Obtener consulta {{ subConsulta.campos_busqueda.length?'':'sin filtros' }}</CButton
                                      >
                                  
                                    <CButton
                                      v-if="subConsulta.campos_busqueda.length"
                                      color="primary"
                                      class="px-4"
                                      @click="limpiarFiltros()"
                                      >Limpiar filtros</CButton
                                    >
                                  
                                    <CButton
                                      v-if="true"
                                      color="primary"
                                      class="px-4"
                                      @click="agregarFiltro()"
                                      >+ Filtro</CButton
                                    >
                                    
                                  </div>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                          

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="items.length" style="margin:0; padding:0; height: 30px; max-width: 100%;">      
                <v-row style="margin:0; padding:0;">
                  <v-col cols="6" md="6" class="d-flex" style="margin:0; padding:0;">
                    <v-text-field
                      prepend-icon="mdi-magnify"
                      v-model="search"
                      label="Buscar en los resultados.."
                      class="mx-4 buscador"
                      style="width: 50%; margin:0; padding:0;"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" class="d-flex text-right" style="margin:0; padding:0; margin-top: -4px !important; padding-right: 10px;">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      class="ma-2 white--text"
                      @click="descargar()"
                      :disabled="loadingDescargar"
                    >
                      CSV
                      <v-icon
                        right
                        dark
                      >
                        mdi-file-download
                      </v-icon>
                      &nbsp;<div class="spinner-border" role="status" v-if="loadingDescargar"></div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container style="margin:0; padding:0;">
            
              <v-card-text>
                <v-data-table
                  v-model="params.selected"
                  :headers="headers"
                  :items="items"
                  :value="selected"
                  @input="enterSelect($event)"
                  item-key="id"
                  class="elevation-1 truncate-table catalogo-table"
                  :search="search"
                  :footer-props="{
                    'items-per-page-text': 'Filas por página:',
                    'items-per-page-options': [5, 10, 50, 100, 200, 500, -1]
                  }"
                  :items-per-page="10"
                  :loading="loading"
                  loading-text="Cargando..."
                  fixed-header
                  height="400px"
                >
                  <!-- Aquí está el template para las acciones -->
                  <template v-slot:item.actions="{ item }">
                    <v-icon 
                      small
                      class="mr-2"
                      @click="verRegistro(item)"
                    >
                      mdi-file
                    </v-icon>
                    <v-icon 
                      small
                      class="mr-2"
                      @click="verImagenes(item)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>

                  <!-- Estilo personalizado para un header específico -->
                  <template v-slot:header.actions="{ header }">
                      <span style="color:#73ffd8;">{{ header.text }}</span>     
                  </template>

                  <!-- Estilo personalizado para un header específico -->
                  <template v-slot:header.denominacion_portafolio="{ header }">
                      <span style="color:#f2ff73;">{{ header.text }}</span>     
                  </template>

                  <!-- Tooltips dinámicos para los headers -->
                  <template v-for="(tooltip, headerKey) in headerTooltips" v-slot:[`header.${headerKey}`]="{ header }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ header.text }} <strong style="font-size: 14px; text-transform: lowercase !important;">ⓘ</strong></span>
                      </template>
                      <span>{{ tooltip }}</span>
                    </v-tooltip>
                  </template>

                  <!-- Aquí se ajusta el template general -->
                  <template v-slot:item="{ item, headers }">
                    <tr>
                      <td v-for="header in headers" :key="header.value">
                        <!-- Verificación para evitar truncamiento en la columna 'actions' -->
                        <template v-if="header.value === 'actions'">
                          <v-row class="d-flex align-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon 
                                small
                                class="mr-2"
                                @click="verRegistro(item)"
                                v-on="on"
                              >
                                mdi-file
                              </v-icon>
                            </template>
                            <span>Ver Detalles</span> <!-- Tooltip para el primer ícono -->
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon 
                                small
                                class="mr-2"
                                @click="verImagenes(item)"
                                v-on="on"
                              >
                                mdi-eye
                              </v-icon>
                            </template>
                            <span>Ver Imágenes</span> <!-- Tooltip para el segundo ícono -->
                          </v-tooltip>
                        </v-row>
                        </template>
                        <template v-else>
                          <!-- Aplica el truncamiento y tooltip a las demás columnas -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-html="truncateText(item[header.value])" v-bind="attrs" v-on="on"></div>
                            </template>
                            <span>{{ item[header.value] }}</span> <!-- Aquí va el texto completo -->
                          </v-tooltip>
                        </template>
                      </td>
                    </tr>
                  </template>
                </v-data-table>

              </v-card-text>
            </div>
            
            <div v-if="contenido=='Informacion'">
              
              <v-tabs v-model="contenidoActiveTab" grow>
                <!-- Las pestañas -->
                <v-tab>Cómo navegar</v-tab>
                <v-tab>Notas</v-tab>
              </v-tabs>

              <v-tabs-items v-model="contenidoActiveTab">

                 <!-- Contenido de la primera pestaña -->
                 <v-tab-item>
                  <v-card flat style="padding: 2px; max-width: 100%;">
                    <v-card-text>
                      <img src="catalogo-instructivo-1-2-3.png" width="100%"/>
                    </v-card-text>
                  </v-card>
                
                </v-tab-item>

                <!-- Contenido de la segunda pestaña -->
                <v-tab-item>
                  
                  <v-container style="padding: 30px 30px 10px 30px; max-width: 100%;">  

                    <v-card class="note-item" v-for="(note, index) in notas" :key="index">
                      <p><strong>{{ index + 1 }} -</strong> {{ note }}</p>
                    </v-card>

                    <v-card class="note-item">
                      <p><strong>3 -</strong> Si requieres acceder al documento “Portafolio de Activos Fijos y Equipos”, sigue los siguientes pasos:
                        <ul>
                          <li>Ingresa a OpenText, haciendo <a href="https://gestiondocumentalintecp.ecopetrol.com.co/OTCS/cs.exe/app" target="_blank">click aquí</a></li>
                          <li>En tu menú de perfil, selecciona “Vista Clásica”</li>
                          <li>Haz click en “SGI – Sistema de Gestión Integral”</li>
                          <li>En “Usar esta búsqueda de vista personalizada”, selecciona la opción “Consulta Documentos Publicados del Sistema de Gestión Integral”</li>
                          <li>En la opción “SGI Código”, ingresa el código del procedimiento (GSE-P-015) y presiona “Buscar”</li>
                          <li>Para acceder al documento, selecciona el “Anexo 3 Portafolio de Activos Fijos y Equipos Ecopetrol”</li>
                        </ul>
                      </p>
                    </v-card>

                  </v-container>
                
                </v-tab-item>

              </v-tabs-items>
           
              <div style="width: 100%; text-align: center;">
                <v-btn style="background-color: #bbd000; color:#fff" text @click="contenido='Datos'">Ir al Catálogo</v-btn>
              </div>
                         
            </div>
            <div v-if="contenido=='Anexos'">
              <v-card flat style="padding: 10px 20px 0 20px; max-width: 100%;">
                <v-card-title>
                  Anexos
                </v-card-title>
                <v-card-text>
                  <div v-if="loadingEnlacesAnexos" class="spinner-border" role="status"></div>
                  
                  <v-row dense class="listaAnexos">
                    <v-col cols="6" v-for="(enlace, index) in enlacesAnexos" :key="enlace.id">
                      <v-list dense>
                        <v-list-item link @click="abrirAnexo(enlace.url)">
                          <v-icon class="mr-3">mdi-file-document</v-icon>
                          <v-list-item-content>
                            <v-list-item-title class="text-h7 wrap-text">
                              {{ enlace.nombre }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>

                  <div style="margin-top:20px; width: 100%; text-align: center;">
                    <v-btn style="background-color: #bbd000; color:#fff" text @click="contenido='Datos'">Ir al Catálogo</v-btn>
                  </div>

                </v-card-text>
                <!-- <a href="https://ecopetrol.sharepoint.com/:x:/r/sites/CAF/Gestin%20Documental%202024/2974_ADMINISTRACI%C3%93N/AD007_Validaci%C3%B3n%20Calidad/CDAM/01.%20Portafolio/01_Versi%C3%B3n%20Vigente/Anexos/Anexo%201_Diccionario%20de%20Categor%C3%ADas,%20Clases%20y%20Perfiles%20de%20Cat%C3%A1logo_V10.xlsx?d=w1fbee8ad6670477496713028fe69df24&csf=1&web=1&e=Ex34UP" download="archivo.xlsx" class="btn btn-primary">Test Descargar Excel</a> -->
              </v-card>
            </div>
            
          </v-col>
        </v-row>
      </v-container>
    </v-card>

      

    <br><br>

    <v-card elevation="2" tile v-if="params.selected.length">
      <v-card-title>Eliminar {{params.selected.length}} registros seleccionados</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="loadingDelete" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingDelete"
          color="primary"
          class="px-4"
          @click="Eliminar()"
          >Eliminar Registros</CButton
        >
      </v-card-actions>
    </v-card>


    <br><br>

    <v-dialog
      v-model="dialog_confirm"
      width="70%"
    >
      <v-card>
        <v-card-title primary-title>¿Realmente desea borrar los registros:?</v-card-title>
        <v-card-text>
          
          <li v-for="item in this.params.selected" :key="item.id">
          id: {{ item.id }} - {{item.placa}}_{{item.proceso}}_{{item.ano}}_{{item.mes}}
          </li>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn_eliminar" @click="ejecutarEliminar()">
            Eliminar
          </v-btn>
          <v-btn class="btn_eliminar" @click="cerrarEliminar()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <br><br>

    <v-dialog
      v-model="dialogDetalles"
      transition="dialog-top-transition"
      width="80%"
    >
      <template v-slot:default="dialogDetalles">
        <v-card>
          <v-toolbar
            style="background-color: #bbd000;"
          >
            <v-toolbar-title>Detalle del Registro</v-toolbar-title>
          </v-toolbar>
          
          <v-card-text>
            <v-container class="divDetalles">
              <div 
                v-for="(valor, clave) in registro"
                :key="clave"
                v-if="headers.some(header => header.value === clave)"
                  class="py-2aaa">
                  <!-- Título de la clave en negrita con color -->
                  <v-typography variant="subtitle1" class="text--primary" style="text-transform: uppercase;">
                    {{ obtenerTituloClave(clave) }}:
                  </v-typography>
                  <!-- Valor con espaciado y ajuste de saltos de línea -->
                  <v-typography variant="body2" class="text-justify">
                    {{ formatearValor(valor) }}
                  </v-typography>
                </div>
              <v-row v-if="false">
                <!-- Iteramos sobre cada campo del registro -->
                <v-col
                  v-for="(valor, clave) in registro"
                  :key="clave"
                  cols="12"
                  md="6"
                  v-if="headers.some(header => header.value === clave)"
                  style="font-size: .6rem !important; padding-top:0; padding-bottom:0;"
                >
                  <div class="py-2">
                    <!-- Título de la clave en negrita con color -->
                    <v-typography variant="subtitle1" class="text--primary" style="text-transform: uppercase;">
                      {{ obtenerTituloClave(clave) }}:
                    </v-typography>
                    <!-- Valor con espaciado y ajuste de saltos de línea -->
                    <v-typography variant="body2" class="text-justify">
                      {{ formatearValor(valor) }}
                    </v-typography>
                  </div>
                  <!-- Divider entre los elementos -->
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn text @click="cerrarDetalles">Volver</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="dialog" transition="dialog-top-transition" width="90%" height="90%">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="#BBD000" dark>{{ dialogTitle }}</v-toolbar>
          <v-card-text class="cardImgs">
            <div class="text-center" v-if="loadingListaImagenes">
              <div class="spinner-border" role="status"></div>
            </div>

            <span v-if="!loadingListaImagenes">
              <v-card elevation="4" v-if="imagenZoom != ''">
                <v-card-text>
                  <v-img
                    v-if="imagenZoom != ''"
                    lazy-src="https://picsum.photos/id/11/10/6"
                    max-height="100%"
                    max-width="100%"
                    :src="imagenZoom"
                    @click="zoom('')"
                  ></v-img>
                </v-card-text>
              </v-card>

              <v-card elevation="4" class="cardImg" v-for="imagen in imagenes" v-if="imagenZoom == ''">
                <v-card-text>
                  <v-img
                    lazy-src="https://picsum.photos/id/11/10/6"
                    max-height="153"
                    max-width="263"
                    :src="getURLImagen(imagen)"
                    @click="zoom(getURLImagen(imagen))"
                  ></v-img>
                  {{ getNombreImagenOriginal(imagen) }}
                </v-card-text>
              </v-card>

              <!-- Mostrar mensaje si no hay imágenes -->
              <v-alert v-if="imagenes.length == 0" type="error" color="orange" style="margin-top:40px;">
                {{ noImagesMessage }}
              </v-alert>
            </span>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="cerrarImagenes()">Volver</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

  </div>
</template>

<script>
import registroFotograficoService from "@/services/registroFotografico.service";
import catalogoDigitalService from '@/services/catalogo.service';
import ListaService from "@/services/lista.service";
import CampoService from "@/services/campo.service";
import UserService from "@/services/user.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";
import api from '@/services/api';


import SvgIcon from '@jamescoyle/vue-icon';
import { mdiAccountGroup, mdiCardPlus, mdiCashRegister, mdiDatabaseCheck, mdiChartGantt, mdiPackageVariantPlus, mdiMapMarkerRadius, mdiTagMultiple, mdiInformationVariantCircleOutline, mdiFileDocumentMultipleOutline } from '@mdi/js';

export default {
  
  data() {
    return {
      mdiAccountGroup: mdiAccountGroup,
      mdiCardPlus: mdiCardPlus,
      mdiCashRegister: mdiCashRegister,
      mdiDatabaseCheck: mdiDatabaseCheck,
      mdiChartGantt: mdiChartGantt,
      mdiPackageVariantPlus: mdiPackageVariantPlus,
      mdiMapMarkerRadius: mdiMapMarkerRadius,
      mdiTagMultiple: mdiTagMultiple,
      mdiInformationVariantCircleOutline: mdiInformationVariantCircleOutline,
      mdiFileDocumentMultipleOutline: mdiFileDocumentMultipleOutline,
      drawer: true,
      mini: true, // Establecer en true para iniciar en mini variante
      params: {
        selected: [],
      },
      subConsulta: {
        campos_busqueda: [], 
        campos_salida: [],
        idTemp: '',
        currentdate: null,
        consulta: null,
        headers: null,
        headers_bd: null
      },
      total_imagenes: 0,
      valid: false,
      loadingDelete: false,
      
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      camposBusqueda: [],
      campos: [
        /*{ text: "Código", value: "id" },
        */
      ],
      opcionesCamposSalida: [],
      headers: [],
      headerTooltips: {
        especialidad_categoria: "SE DIVIDEN TOMANDO EN CUENTA LA FORMA PRIMARIA DE FUNCIONAMIENTO DEL EQUIPO, ES IMPORTANTE NO CONFUNDIR ESTAS CATEGORÍAS CON LAS ESPECIALIDADES, ESTAS CATEGORÍAS NO DELIMITAN LOS GRUPOS DE ESPECIALISTAS QUE MANTIENEN LOS EQUIPOS",
        tipo_de_objeto: "EL TIPO DE OBJETO BUSCA CLASIFICAR LOS EQUIPOS, TOMANDO EN CUENTA LA FORMA PRIMARIA DE FUNCIONAMIENTO DE LOS MISMOS",
        tipo_de_equipo_nombre: "EL TIPO DE EQUIPO “ACTIVO NO INDUSTRIAL (N)”, SOLO PODRÁ SER UTILIZADO POR EL ÁREA DE INFRAESTRUCTURA NO INDUSTRIAL DE LA GSC",
        tipo_de_equipo_codigo: "EL TIPO DE EQUIPO “ACTIVO NO INDUSTRIAL (N)”, SOLO PODRÁ SER UTILIZADO POR EL ÁREA DE INFRAESTRUCTURA NO INDUSTRIAL DE LA GSC",
        denominacion_catalogo_de_activos_fijos: "ESTA DENOMINACIÓN ES DE CARÁCTER INFORMATIVO Y NO ES VÁLIDA PARA LA CREACIÓN DE DATOS MAESTROS",
        denominacion_complementaria: "ESTE CAMPO SOLAMENTE SERÁ ASIGNADO EN EL MÓDULO DE AA",
        rango_minimo_de_capacidad: "SI EL BIEN A CATALOGAR SE ENCUENTRA FUERA DE LOS RANGOS ESTABLECIDOS, SE CONSIDERARÁ COMO EQUIPO ÚNICAMENTE",
        magnitud: "EL ATRIBUTO “MAGNITUD”, SOLO APLICA PARA LOS BIENES QUE SON CONSIDERADOS ACTIVOS FIJOS",
        unidad_de_medida: "EL ATRIBUTO “UNIDAD DE MEDIDA”, SOLO APLICA PARA LOS BIENES QUE SON CONSIDERADOS ACTIVOS FIJOS"
      },
      perfilGeneral: [
        { text: "Acciones", value: "actions" },
        { text: "Especialidad / categoría", value: "especialidad_categoria" },
        { text: "Tipo de objeto", value: "tipo_de_objeto" },
        { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria ", value: "denominacion_complementaria" },
        { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad ", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        { text: "Clase equipo", value: "clase_equipo" },
        { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        { text: "Tipo de equipo", value: "tipo_de_equipo" },
        { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        { text: "Perfil catálogo", value: "perfil_catalogo" },
        { text: "Clase + tipo", value: "clase_tipo" },
        { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        { text: "Magnitud", value: "magnitud" },
        { text: "Unidad de medida", value: "unidad_de_medida" },
        { text: "Grupo", value: "grupo" },
        { text: "Subgrupo", value: "subgrupo" },
        { text: "Clase", value: "clase" },
        { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        { text: "Definición clase contable", value: "definicion_clase_contable" },
        { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilIncorporacion: [
        { text: "Acciones", value: "actions" },
        { text: "Especialidad / categoría", value: "especialidad_categoria" },
        { text: "Tipo de objeto", value: "tipo_de_objeto" },
        { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        // { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria", value: "denominacion_complementaria" },
        { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        { text: "Clase equipo", value: "clase_equipo" },
        { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        // { text: "Tipo de equipo", value: "tipo_de_equipo" },
        // { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        { text: "Perfil catálogo", value: "perfil_catalogo" },
        { text: "Clase + tipo", value: "clase_tipo" },
        { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        { text: "Magnitud", value: "magnitud" },
        { text: "Unidad de medida", value: "unidad_de_medida" },
        // { text: "Grupo", value: "grupo" },
        // { text: "Subgrupo", value: "subgrupo" },
        { text: "Clase", value: "clase" },
        // { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        // { text: "Definición clase contable", value: "definicion_clase_contable" },
        { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        // { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        // { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        // { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilCapitalizacion: [
        { text: "Acciones", value: "actions" },
        // { text: "Especialidad / categoría", value: "especialidad_categoria" },
        // { text: "Tipo de objeto", value: "tipo_de_objeto" },
        // { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        // { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria ", value: "denominacion_complementaria" },
        { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad ", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        // { text: "Clase equipo", value: "clase_equipo" },
        // { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        // { text: "Tipo de equipo", value: "tipo_de_equipo" },
        // { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        // { text: "Perfil catálogo", value: "perfil_catalogo" },
        // { text: "Clase + tipo", value: "clase_tipo" },
        // { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        { text: "Magnitud", value: "magnitud" },
        { text: "Unidad de medida", value: "unidad_de_medida" },
        // { text: "Grupo", value: "grupo" },
        // { text: "Subgrupo", value: "subgrupo" },
        // { text: "Clase", value: "clase" },
        // { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        // { text: "Definición clase contable", value: "definicion_clase_contable" },
        // { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        // { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        // { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        // { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        // { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilCDAM: [
        { text: "Acciones", value: "actions" },
        { text: "Especialidad / categoría", value: "especialidad_categoria" },
        { text: "Tipo de objeto", value: "tipo_de_objeto" },
        { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        // { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria ", value: "denominacion_complementaria" },
        // { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad ", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        // { text: "Clase equipo", value: "clase_equipo" },
        // { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        // { text: "Tipo de equipo", value: "tipo_de_equipo" },
        // { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        { text: "Perfil catálogo", value: "perfil_catalogo" },
        { text: "Clase + tipo", value: "clase_tipo" },
        { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        // { text: "Magnitud", value: "magnitud" },
        { text: "Unidad de medida", value: "unidad_de_medida" },
        // { text: "Grupo", value: "grupo" },
        // { text: "Subgrupo", value: "subgrupo" },
        { text: "Clase", value: "clase" },
        { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        // { text: "Definición clase contable", value: "definicion_clase_contable" },
        // { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        // { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        // { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        // { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        // { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilPlaneacion: [
        { text: "Acciones", value: "actions" },
        // { text: "Especialidad / categoría", value: "especialidad_categoria" },
        // { text: "Tipo de objeto", value: "tipo_de_objeto" },
        // { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        // { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria ", value: "denominacion_complementaria" },
        // { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad ", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        // { text: "Clase equipo", value: "clase_equipo" },
        // { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        // { text: "Tipo de equipo", value: "tipo_de_equipo" },
        // { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        // { text: "Perfil catálogo", value: "perfil_catalogo" },
        // { text: "Clase + tipo", value: "clase_tipo" },
        // { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        // { text: "Magnitud", value: "magnitud" },
        // { text: "Unidad de medida", value: "unidad_de_medida" },
        // { text: "Grupo", value: "grupo" },
        // { text: "Subgrupo", value: "subgrupo" },
        // { text: "Clase", value: "clase" },
        // { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        // { text: "Definición clase contable", value: "definicion_clase_contable" },
        // { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        // { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        // { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        // { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        // { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilMateriales: [
        { text: "Acciones", value: "actions" },
        // { text: "Especialidad / categoría", value: "especialidad_categoria" },
        // { text: "Tipo de objeto", value: "tipo_de_objeto" },
        // { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        // { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria ", value: "denominacion_complementaria" },
        { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad ", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        // { text: "Clase equipo", value: "clase_equipo" },
        // { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        // { text: "Tipo de equipo", value: "tipo_de_equipo" },
        // { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        // { text: "Perfil catálogo", value: "perfil_catalogo" },
        // { text: "Clase + tipo", value: "clase_tipo" },
        // { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        // { text: "Magnitud", value: "magnitud" },
        // { text: "Unidad de medida", value: "unidad_de_medida" },
        // { text: "Grupo", value: "grupo" },
        // { text: "Subgrupo", value: "subgrupo" },
        // { text: "Clase", value: "clase" },
        // { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        // { text: "Definición clase contable", value: "definicion_clase_contable" },
        // { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        // { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        // { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilAtencionRegional: [
        { text: "Acciones", value: "actions" },
        { text: "Especialidad / categoría", value: "especialidad_categoria" },
        { text: "Tipo de objeto", value: "tipo_de_objeto" },
        { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria ", value: "denominacion_complementaria" },
        { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad ", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        // { text: "Clase equipo", value: "clase_equipo" },
        // { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        // { text: "Tipo de equipo", value: "tipo_de_equipo" },
        // { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        { text: "Perfil catálogo", value: "perfil_catalogo" },
        { text: "Clase + tipo", value: "clase_tipo" },
        { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        // { text: "Magnitud", value: "magnitud" },
        { text: "Unidad de medida", value: "unidad_de_medida" },
        // { text: "Grupo", value: "grupo" },
        // { text: "Subgrupo", value: "subgrupo" },
        { text: "Clase", value: "clase" },
        { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        // { text: "Definición clase contable", value: "definicion_clase_contable" },
        { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilCaracterizacion: [
        { text: "Acciones", value: "actions" },
        // { text: "Especialidad / categoría", value: "especialidad_categoria" },
        { text: "Tipo de objeto", value: "tipo_de_objeto" },
        { text: "Tipo de equipo (nombre)", value: "tipo_de_equipo_nombre" },
        // { text: "Tipo de equipo (código)", value: "tipo_de_equipo_codigo" },
        { text: "Tipo de bien", value: "tipo_de_bien" },
        // { text: "Denominación catálogo de activos fijos", value: "denominacion_catalogo_de_activos_fijos" },
        { text: "Denominación (portafolio)", value: "denominacion_portafolio" },
        { text: "Denominación complementaria ", value: "denominacion_complementaria" },
        { text: "Definición", value: "definicion" },
        { text: "Rango mínimo de capacidad ", value: "rango_minimo_de_capacidad" },
        { text: "Observaciones y anexo técnico", value: "observaciones_y_anexo_tecnico" },
        // { text: "Clase equipo", value: "clase_equipo" },
        // { text: "Denominación clase equipo", value: "denominacion_clase_equipo" },
        // { text: "Tipo de equipo", value: "tipo_de_equipo" },
        // { text: "Denominación tipo de equipo", value: "denominacion_tipo_de_equipo" },
        { text: "Perfil catálogo", value: "perfil_catalogo" },
        { text: "Clase + tipo", value: "clase_tipo" },
        { text: "Denominación clase + tipo", value: "denominacion_clase_tipo" },
        { text: "Magnitud", value: "magnitud" },
        { text: "Unidad de medida", value: "unidad_de_medida" },
        // { text: "Grupo", value: "grupo" },
        // { text: "Subgrupo", value: "subgrupo" },
        { text: "Clase", value: "clase" },
        // { text: "Descripción clase contable", value: "descripcion_clase_contable" },
        // { text: "Definición clase contable", value: "definicion_clase_contable" },
        // { text: "Tipo de marcación", value: "tipo_de_marcacion" },
        // { text: "Lugar de marcación", value: "lugar_de_marcacion" },
        { text: "Vida útil técnica sugerida", value: "vida_util_tecnica_sugerida" },
        // { text: "Activo objeto de inventario", value: "activo_objeto_de_inventario" },
        // { text: "Método de inventario", value: "metodo_de_inventario" }
      ],
      perfilSeleccionado: null,
      registro: null,
      items: [],
      users: [],
      imagenes: [],
      baseURL: '',
      search: null,
      contenido: "Informacion",
      contenidoActiveTab: 0, // Tab activa inicial (0 para Item1, 1 para Item2)
      notas: [
        "El portafolio de activos fijos y equipos detalla los bienes que se reconocerán individualmente en SAP como activos fijos. Las partidas correspondientes a componentes de equipos, piezas de repuesto, equipo de reserva y el equipo auxiliar, se reconocerán dentro de las cuentas de activos fijos como mayores valores o adiciones, si cumplen con la definición conforme la NIC 16 - propiedades, planta y equipo.",
        'En el portafolio de activos fijos y equipos se incluyó el anexo "tuberías", dicho anexo clasifica las tuberías en sus diferentes denominaciones por tipología y su respectivo tipo de bien como activo y/o activo + equipo. Para la revisión, validación y ajuste de los datos maestros históricos de SAP AA que tengan asociada la denominación "línea de flujo" se estará ejecutando un plan de trabajo por definir a futuro, durante el tiempo que dure este plan de trabajo se permitirá el reconocimiento como activos fijos de los mayores valores que se realicen a las líneas de flujo, siempre y cuando los activos fijos principales se hayan reconocido antes del 01/09/2022.'
      ],

      params: {
        archivoLayoutId: undefined, 
        selected: []
      },
      selected: [],
      headersFiltros: [
        { text: "Filtro", value: "filtro", sortable: false },
        { text: "Valor", value: "valor", sortable: false },
        { text: "Borrar", value: "actions", sortable: false },
      ],
      enlacesAnexos: [],
      loading: false,
      loadingListaImagenes: false,
      loadingEnlacesAnexos: false,
      loadingDescargar: false,
      message: "",
      dialog: false,
      dialogDetalles: false,
      dialog_confirm:false,
      dialogTitle: "",
      filtroImagen: "_2",
      noImagesMessage: '', 
      imagenZoom: "",
      tab: null,
      itemsTabs: [
        '.', '..', '...', '....',
      ]
    };
  },
  components: {
    SvgIcon
  },
  watch: {
  },
  computed: {
    ...mapState(["permissions"])

  },
  created() {
    // Asigna el array importado a this.items
    //this.items = catalogoDigitalService.getItems();
  },
  mounted() {
    //if (!this.permissions.includes("Repositorio Fotográfico - Consultar"))
      //this.$router.push("/dashboard");
      
    this.baseURL = api.defaults.baseURL
    if( this.baseURL.charAt(this.baseURL.length - 1) != '/'){
      this.baseURL = this.baseURL + '/'
    }

    this.seleccionarPerfil("General")
    this.consultar() // Debe estar posterior a seleccionarPerfil
    this.contenido = "Informacion" // Debe estar posterior a seleccionarPerfil
    this.getEnlacesAnexos()
  },
  computed: {
    filteredCampos() {
      const selectedValues = this.subConsulta.campos_busqueda.map(item => item.filtro);
      return this.campos.map(campo => ({
        ...campo,
        disabled: selectedValues.includes(campo.value),
      }));
    },
  },
  methods: {
    // Método para seleccionar el perfil y cambiar los headers
    seleccionarPerfil(perfil) {
      
      this.contenido = 'Datos'

      this.perfilSeleccionado = perfil
      switch (perfil) {
        case 'General':
          this.headers = this.perfilGeneral;
          break;
        case 'Incorporación':
          this.headers = this.perfilIncorporacion;
          break;
        case 'Capitalización':
          this.headers = this.perfilCapitalizacion;
          break;
        case 'CDAM':
          this.headers = this.perfilCDAM;
          break;
        case 'Planeación':
          this.headers = this.perfilPlaneacion;
          break;
        case 'Materiales':
          this.headers = this.perfilMateriales;
          break;
        case 'Atención Regional':
          this.headers = this.perfilAtencionRegional;
          break;
        case 'Caracterización':
          this.headers = this.perfilCaracterizacion;
          break;
        default:
          this.headers = [];
      }

      this.campos = this.headers.slice(1);

      console.log(`Perfil seleccionado: ${perfil}`);
    },
    truncateText(text) {
      if (!text) return '';
      const truncated = text.toString().length > 40 ? text.toString().slice(0, 40) + '...' : text;
      return truncated;
    },
    obtenerTitulo(texto) {
      // Extrae la parte del título principal (antes del paréntesis)
      const match = texto.match(/^([^(]+)/);
      return match ? match[1].trim() : texto;
    },
    obtenerSubtitulo(texto) {
      // Extrae la parte del subtítulo (dentro del paréntesis)
      const match = texto.match(/\(([^)]+)\)/);
      return match ? match[1] : null;
    },
    enterSelect(values) {
      console.log(values)
      if (values.length == this.itemsPerPage) {
        alert('selected all')
      }

    },
    getNombresFotosByDenominacion(denominacion, clase, regla) {
      this.imagenes = []
      this.loadingListaImagenes = true

      catalogoDigitalService.getNombresFotos(denominacion, clase, regla).then(
        (response) => {
          this.imagenes = response.data

          console.log("El total de imágenes sin filtro.")
          console.log(this.imagenes)

          // Filtrar las imágenes según el valor de filtroImagen
          if (this.filtroImagen) {
            this.imagenes = this.imagenes.filter((imagen) => {
              // Obtener el nombre corto de la imagen (sin la parte después del último guion bajo)
              const nombreCorto = this.getNombreImagenOriginal(imagen);

              // Filtrar las imágenes que terminan con el sufijo de filtroImagen
              return nombreCorto.endsWith(this.filtroImagen);
            })
          }

          // Filtrar para que no se repitan imágenes con la misma placa
          const placasProcesadas = new Set();
          this.imagenes = this.imagenes.filter((imagen) => {
            const placa = imagen.split('_')[0]; // Obtener la parte antes del primer guion bajo
            if (placasProcesadas.has(placa)) {
              return false; // Ya existe una imagen con esta placa
            } else {
              placasProcesadas.add(placa);
              return true; // Primera vez que se encuentra esta placa
            }
          });

          // Limitar el número de imágenes a un máximo de 6
          this.imagenes = this.imagenes.slice(0, 6);

          this.loadingListaImagenes = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log("Error al obtener las imágenes:", error)
          this.loadingListaImagenes = false
        }
      )
    },
    getEnlacesAnexos() {
      this.loadingEnlacesAnexos = true;
      catalogoDigitalService.getEnlacesAnexos().then(
        (response) => {
          this.enlacesAnexos = response.data.data;
          this.loadingEnlacesAnexos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingEnlacesAnexos = false;
        }
      );
    },
    abrirAnexo(url) {
      if (url) {
        window.open(url, "_blank"); // Abre el enlace en una nueva pestaña
      } else {
        console.warn("URL no válida:", url);
      }
    },
    getCampoById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre:''
    },
    getAliasById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre_alias:''
    },
    getAliasByNombre(nombre){
      let campo = this.campos.find(e => e.nombre == nombre)
      return campo?campo.nombre_alias:''
    },
    getValorCampoByNombre(nombre, item){
      return item?item[nombre]:''
    },
    consultar() {
      this.loading = true
      this.total_imagenes = 0
      this.params.selected= [];

      console.log("Verificando estado de headers...")
      console.log(this.headers.length)
      console.log(this.headers)
      this.subConsulta.headers = this.headers
        .filter((header, index) => index !== 0) // Omitir el primer elemento ("Acciones")
        .map(header => header.text);           // Obtener solo los valores de `text`

      this.subConsulta.headers_bd = this.headers
        .filter((header, index) => index !== 0) // Omitir el primer elemento ("Acciones")
        .map(header => header.value);           // Obtener solo los valores de `text`
        

      console.log("subConsulta.headers:::")
      console.log(this.subConsulta.headers)

      this.subConsulta.currentdate = new Date()
      this.subConsulta.idTemp = 'Temp'+this.hashCode(JSON.stringify(this.subConsulta)) 
      
      catalogoDigitalService.consultar(this.subConsulta).then(
        (response) => {
          this.items = response.data
          console.log(this.items)
          for(let i=0; i< this.items.length; i++)
            this.total_imagenes += this.items[i].imagenes
          this.loading = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("presentación del error:")
            console.log(error)
            this.loading = false
        }
      )
    },
    limpiarFiltros () {
      this.subConsulta.campos_busqueda.forEach(campo => {
        campo.filtro = "";
        campo.valor = "";
      });
    },
    agregarFiltro() {
      this.subConsulta.campos_busqueda.push({
        filtro: "",
        valor: "",
      });
    },
    deleteItem(item) {
      let i = this.subConsulta.campos_busqueda.indexOf(item);
      this.subConsulta.campos_busqueda.splice(i, 1);
    },
    onFiltroChange(value) {
      console.log("Cambio en filtro:", value);
    },
    verRegistro(item) {
      this.registro = item
      this.dialogDetalles = true
    },
    obtenerTituloClave(clave) {
      const header = this.headers.find(header => header.value === clave);
      return header ? header.text : clave; // Retorna el texto o la clave si no se encuentra
    },
    formatearValor(valor) {
      return valor.replace(/\n/g, ', '); // Reemplazamos saltos de línea por comas
    },
    verImagenes(item) {
      this.imagenes = []
      console.log("Solicitando listado de imagenes al...")
      console.log(item["denominacion_portafolio"])
      console.log(item["clase"])

      this.dialogTitle = `DENOMINACIÓN: ${item["denominacion_portafolio"]}`

      this.noImagesMessage = item["mensaje_no_foto"];

      if(item["filtro_registro_fotografico"] == 1) {
        
      } else {
        this.filtroImagen = '_2';
        if(item["filtro_registro_fotografico"] == 2)
          this.filtroImagen = '_1';
        console.log('filtroImagen:')
        console.log(this.filtroImagen)
        this.getNombresFotosByDenominacion(item["denominacion_portafolio"], item["clase"], item["filtro_registro_fotografico"]) 
      }
        
      this.dialog = true
    },
    getSoloNombreImagen(fileName){
      const lastIndex = fileName.lastIndexOf('.');
      return fileName.substring(0, lastIndex);
    },
    agregarMasReciente(imagenes, imgNueva){ 
      var imagenExistente = false
      for(let i = 0; i < imagenes.length; i++) {
        //console.log("---Comp. contra img "+i)
        var imgExistente = imagenes[i]
        
        var arrayTemp = this.getSoloNombreImagen(imgExistente).split('_')
        var numCargaImgExistente = arrayTemp[arrayTemp.length-1]
        var nombreImgExistenteNoNum = `${arrayTemp[0]}_${arrayTemp[1]}_${arrayTemp[2]}_${arrayTemp[3]}_${arrayTemp[4]}`
        
        var arrayTemp2 = this.getSoloNombreImagen(imgNueva).split('_')
        var numCargaImgNueva = arrayTemp2[arrayTemp2.length-1]
        var nombreImgNuevaNoNum = `${arrayTemp2[0]}_${arrayTemp2[1]}_${arrayTemp2[2]}_${arrayTemp2[3]}_${arrayTemp2[4]}`

        //console.log('nombreImgExistenteNoNum ' + nombreImgExistenteNoNum)
        //console.log('nombreImgNuevaNoNum ' + nombreImgNuevaNoNum)
        if(nombreImgExistenteNoNum == nombreImgNuevaNoNum) {
          imagenExistente = true
          //console.log('Imagen repetida!! ' + nombreImgExistenteNoNum)
          //console.log(`${numCargaImgNueva} > ${numCargaImgExistente}`)
          if(numCargaImgNueva > numCargaImgExistente) { //Dejar la mas reciente
            imagenes[i] = imgNueva
          }
        }
      }
      if (!imagenExistente){
        //console.log(`>>Se agrega ${imgNueva}`)
        imagenes.push(imgNueva)
      }
        
      return imagenes
    },
    zoom(imagen) {
      this.imagenZoom = imagen
    },
    getNombreImagenOriginal(imagen){
      let nombre = imagen.split('/')
      nombre = nombre[nombre.length-1]
      nombre = nombre.split('_')
      return `${nombre[0]}_${nombre[1]}` 
    },
    getURLImagen(imagen){
      console.log(`${this.baseURL}api/registros_fotograficos/foto/${imagen}`)
      return `${this.baseURL}api/registros_fotograficos/foto/${imagen}`
    },
    cerrarDetalles(){
      this.dialogDetalles = false
    },
    cerrarImagenes(){
      this.zoom('')
      this.dialog = false
    },
    descargar() {
      this.loadingDescargar = true
      catalogoDigitalService.getArchivo(this.subConsulta.idTemp).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `Consulta_Catalogo.csv`;
          this.saveAs(blob, fileName);
          this.loadingDescargar = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loadingDescargar = false
        }
      );
    },
    saveAs(blob, fileName) {
      var url = window.URL.createObjectURL(blob);

      var anchorElem = document.createElement("a");
      anchorElem.style = "display: none";
      anchorElem.href = url;
      anchorElem.download = fileName;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);

      // On Edge, revokeObjectURL should be called only after
      // a.click() has completed, atleast on EdgeHTML 15.15048
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    hashCode (cadena) {
      var hash = 0,
        i, chr;
      if (cadena.length === 0) return hash;
      for (i = 0; i < cadena.length; i++) {
        chr = cadena.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    depurar_cadena (text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      text = text.replace(/ /g,"_");
      text = text.replace(/"""KEY"""/g,"_key");
      if (text == "KEY")
        text = "_key"
      text = text.toLowerCase();
      return text
    },
  },
};
</script>

<style>

.v-dialog__content {
    z-index: 1030 !important;
}
.v-tooltip__content {
  z-index: 1050 !important;
}

.divFiltros {
  padding: 0;
}

.divFiltros, .divFiltros label {
  font-size: .5rem !important;
}

.divFiltros .v-text-field .v-label {
    top: 16px;
}
.divFiltros .v-text-field__slot .v-label {
    top: 6px;
}

.divFiltros div {
  font-size: .6rem !important;
  font-weight: 400;
  line-height: 0.9rem;
  letter-spacing: .0071428571em;
  padding-top: 0;
  padding-bottom: 0;
}

.divFiltros .tabla th {
    height: 20px;
}

.divFiltros .v-input input {
    max-height: 20px;
}
.divFiltros .v-input .v-label {
    height: 10px;
    line-height: 10px;
}
.divFiltros .v-list-item {
  min-height: 25px;
}

.divDetalles {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap; /* Permitir que los elementos se muevan a la siguiente línea si es necesario */
  gap: 10px; /* Espaciado entre columnas */
}
.divDetalles div {
  flex: 1 1 calc(50% - 16px); /* Dos columnas ocupan cada una el 50% menos el espacio del gap */
  box-sizing: border-box; /* Incluir padding y border en el tamaño total */
  padding: 5px 10px 5px 10px;
  background-color: #f0f0f0; /* Fondo para identificar los div */
  border: 1px solid #ccc;

  font-size: .6rem !important;
  font-weight: 400;
  line-height: 0.9rem;
  letter-spacing: .0071428571em;
}

/* Para resoluciones menores */
@media (max-width: 768px) {
  .divDetalles div {
    flex: 1 1 100%; /* Cada columna ocupa el 100% del ancho en pantallas pequeñas */
  }
}

.tabla{
  width: 100%;
  border-width: 0.5px;
}
.tabla th{
  color: rgba(0,0,0,.6);
  font-size: .75rem;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
}
.tabla, .tabla tr {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #e1e1e1;
}
.tabla td {
  padding-left: 16px;
  padding-right: 16px;
}

.cardImgs {
  overflow:auto;
}

.cardImg {
  margin: 1%;
  width: 28%;
  float: left;
}

/* Aplica el estilo a todos los encabezados (th) del v-data-table */
.catalogo-table .v-data-table-header th {
  background-color: #004036 !important; /* Cambia el color de fondo */
  color: white !important; /* Cambia el color del texto */
  line-height: 15px;
  text-transform: uppercase;
  font-size: .5rem !important;
  text-align: center;
  /*z-index: 0 !important;*/
}

.catalogo-table tbody td {
  font-size: .5rem !important;
}
</style>

<style scoped>
.v-navigation-drawer {
  height: 100%;
}
.v-divider {
  margin: 10px 0;
}
v-application .primary {
    background-color: #bbd000 !important;
    border-color: #bbd000 !important;
}
.buscador {
    /*border: 1px dashed rgba(0,0,0, .4);*/
}
.titulo-principal {
  font-weight: bold;
  font-size: 16px;
}

.subtitulo {
  font-size: 12px;
  color: grey;
}

.notes-card {
  max-width: 600px;
  margin: auto;
  font-size: 16px;
  text-transform: lowercase;
}

h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.note-item p {
  padding:20px;
  font-size: 10px;
  line-height: 1.6;
}

.note-item ul {
  margin-top: 10px;
  margin-left: 20px;
}

.disabled-item-class {
  color: grey;
  text-decoration: line-through;
}

.wrap-text {
  white-space: normal;
  word-wrap: break-word;
  overflow: visible;
}
.listaAnexos div {
  margin: 0; /* Elimina márgenes */
  padding-top: 4px !important; /* Reduce padding superior */
  padding-bottom: 4px !important; /* Reduce padding inferior */
}

.listaAnexos .row--dense>.col, .row--dense>[class*=col-] {
    padding: 0 4px;
    max-height: 55px;
}

</style>